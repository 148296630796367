html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Rubik', sans-serif;
  overflow-y: hidden;
}

#root {
  height: 100%;
}

#container {
  height: 100%
}
